import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import moment from 'moment-timezone'
import NumberInput from '../../components/NumberInput';
import Loader from '../Loader';
import LocalizedStrings from '../Localization';

import { getFeesAndPrices, addContract, getFixedFee } from '../../api/sdps';
import { getPartners, getPartnerContacts } from '../../api/partner';

import 'react-day-picker/lib/style.css'

import config from '../../api/config';

moment.tz.setDefault('Europe/Brussels')


class NewContract extends Component {

  constructor(props) {
    super(props);

    this.initialState = {
      isLoading: false,
      partners: [],
      partnerContacts: [],
      initialMonth: moment().month(),
      contract: {
        switchType: '',
        startDate: '',
        platformFee: '',
        vreg_id: undefined,
        minPlatformFee: undefined,
        contract_partner_id: config.YUSO_PARTNER_ID,
        contract_partner_contact_id: config.YUSO_PARTNER_CONTACT_ID,
        activeCommission: 0,
        defaultPartnerFee: 0,
        partnerFee: 0,
        minImbalanceIndex: 100
      },
      adding: false,
      error: false,
    }

    this.state = { ...this.initialState };

    this.resetForm = this.resetForm.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.numberField = this.numberField.bind(this);
    this.disabledDays = this.disabledDays.bind(this);
    this.updatePartners = this.updatePartners.bind(this);
    this.updatePartnerContacts = this.updatePartnerContacts.bind(this);
    this.onPartnerChange = this.onPartnerChange.bind(this);
    this.onPartnerContactChange = this.onPartnerContactChange.bind(this);
    this.updateFeesAndPrices = this.updateFeesAndPrices.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onFixedFeeChange = this.onFixedFeeChange.bind(this);
    this.onPlatformFeeChange = this.onPlatformFeeChange.bind(this);

    this.strings = LocalizedStrings({
      en: {
        generateContract: "Generate contract",
        SolarImbalanceIndex: "Solar Imbalance index",
        WindImbalanceIndex: "Wind Imbalance index ",
        loading: "Loading...",
        fixedFee: "Fixed fee",
        minFixedFee: "Minimum fixed fee is",
        standardPercentage: "Standard percentage is",
        error: "There was an error while adding the SDP. Please try again.",
        errorRequired: "All marked fields are required.",
        errorWrongFormat: "The VREG ID must start with 'PVZG', 'PVZ', 'G' or 'WKK'. Example: PVZG12345, PVZ123456, G12345678",
        contractParams: "Contract parameters",
        supplyPoint: "of the supply point",
        injectionPoint: "of the injection point",
        sdpPoint: "of the SDP",
        switchType: "Switch type",
        switchTypeMoveIn: "New installation",
        switchTypeNormalSwitch: "Supplier switch",
        switchTypeSwitchAfterEoC: "Supplier switch at end of the contract",
        switchTypeInternalSwitch: "Internal switch",
        startDate: "Start date",
        vreg: "VREG ID",
        product: "Product",
        platformFee: "Platform fee",
        priceGSC: "Price GSC",
        priceWKC: "Price WKC",
        GOprice: "GO price",
        clear: "Clear",
        form: "form",
        addedContract: "Contract was created successfully!",
        startDateNote: "Please fill in the correct date on which we should take over your connection point once the contract is signed. Keep in mind the notice period of your current contract.",
        partnerLabel: "Partner",
        partnerCompany: "Partner company",
        partnerFee: "Partner fee",
        partnerFeeNote1: "Partner fee is calculated based on the platform fee.",
        partnerFeeNote2: "Partner fee is calculated based on the fixed fee.",
        minPlatformFee: "Calculated minimum platform fee is",
        previousFeeSupply: "The company has previous supply contract with Platfrom fee of",
        previousFeeInjection: "The company has previous injection contract with Platfrom fee of",
        previousPriceGSC: "The company has previous supply contract with GSC price of",
        previousPriceWKC: "The company has previous supply contract with WKC price of",
        previousGOprice: "The company has previous injection contract with GO price of",
        defaultGOprice: "The default GO price is",
        leaveEmptyCert: "Leave the field above empty to keep the previous value.",
        leaveEmpty: "Leave the field above empty to keep the default value.",
        partnerSelect: "Partner",
        adminFields: "This fields are visible and can be set only by administrators",
        if: "If",
        partner: "partner",
        adminitrator: "adminitrator",
        user: "common user",
        partnerInfo1: "The partner and partner contact for one SDP are set depending on the logged in user:",
        partnerInfo2: "adds an SDP then contract will be linked with that partner company and partner user that added the SDP.",
        partnerInfo3: "adds an SDP the partner will default to Yuso.",
        partnerInfo4: "Only administrators have right to change the partner and partner contact.",
        partnerInfo5: "adds an SDP then partner will always default to Yuso."
      },
      nl: {
        generateContract: "Contract genereren",
        loading: "Bezig met laden...",
        SolarImbalanceIndex: "Solar Onbalans index",
        WindImbalanceIndex: "Wind Onbalans index",
        fixedFee: "Vaste vergoeding",
        minFixedFee: "Minimum vaste vergoeding bedraagt",
        standardPercentage: "Standard percentage is",
        error: "Er is een fout opgetreden tijdens het toevoegen van de SDP. Probeer het opnieuw.",
        errorRequired: "Alle gemarkeerde velden zijn verplicht.",
        errorWrongFormat: "De VREG-ID moet beginnen met 'PVZG', 'PVZ', 'G' or 'WKK'. Voorbeeld: PVZG12345, PVZ123456, G12345678",
        contractParams: "Contractparameters",
        supplyPoint: "van het leveringspunt",
        injectionPoint: "van het injectiepunt",
        sdpPoint: "van de SDP",
        switchType: "Overstap type",
        switchTypeMoveIn: "Nieuwe installatie",
        switchTypeNormalSwitch: "Overstap naar andere leverancier",
        switchTypeSwitchAfterEoC: "Overstap naar andere leverancier bij einde van contract",
        switchTypeInternalSwitch: "Intern overstap",
        startDate: "Begin datum",
        vreg: "VREG ID",
        product: "Product",
        platformFee: "Platformkosten",
        priceGSC: "Prijs GSC",
        priceWKC: "Prijs WKC",
        GOprice: "GO prijs",
        clear: "Wis",
        form: "alle velden",
        addedContract: "Contract is met succes aangemaakt!",
        startDateNote: "Vul de juiste datum in waarop wij uw aansluitpunt moeten overnemen na ondertekening van het contract. Houd rekening met de opzegtermijn van uw huidige contract.",
        partnerLabel: "Partner",
        partnerCompany: "Partnerbedrijf",
        partnerFee: "Partner commissie",
        partnerFeeNote1: "Partner commissie worden berekend op basis van de platformkosten.",
        partnerFeeNote2: "Partner commissie worden berekend op basis van de vaste vergoeding.",
        minPlatformFee: "Berekende minimum platformkosten zijn",
        previousFeeSupply: "Het bedrijf heeft vorige leveringscontract met Platformkosten van",
        previousFeeInjection: "Het bedrijf heeft eerder een injectiecontract met Platformkosten van",
        previousPriceGSC: "Het bedrijf heeft vorig leveringscontract met GSC-prijs van",
        previousPriceWKC: "Het bedrijf heeft vorige leveringscontract met WKC-prijs van",
        previousGOprice: "Het bedrijf heeft eerder een injectiecontract afgesloten met de GO-prijs van",
        defaultGOprice: "Het standaard GO-prijs is",
        leaveEmptyCert: "Laat het veld hierboven leeg om de vorige waarde te behouden.",
        leaveEmpty: "Laat het veld hierboven leeg om de standaard waarde te behouden.",
        partnerSelect: "Partner",
        adminFields: "Deze velden zijn zichtbaar en kunnen alleen door beheerders worden ingesteld",
        if: "Als",
        partner: "partner",
        adminitrator: "beheerder",
        user: "gemeenschappelijke gebruiker",
        partnerInfo1: "De partner en het partnercontact voor één SDP worden ingesteld afhankelijk van de ingelogde gebruiker:",
        partnerInfo2: "voegt een SDP toe, dan wordt het contract gekoppeld aan dat partnerbedrijf en de partnergebruiker die de SDP heeft toegevoegd.",
        partnerInfo3: "voegt een SDP toe, de partner zal standaard Yuso gebruiken.",
        partnerInfo4: "Alleen beheerders hebben het recht om de partner en het partnercontact te wijzigen.",
        partnerInfo5: "voegt een SDP toe, dan zal de partner altijd standaard Yuso gebruiken.",
      }
    }, this.props.lang);
  }

  componentDidMount() {
    this.setState({
      contract: {
        ...this.state.contract,
        ...this.props.sdp,
        contract_partner_id: this.props.selectedCompany.role === 'partner' ? this.props.selectedCompany.contact_partner_id : config.YUSO_PARTNER_ID,
        contract_partner_contact_id: this.props.selectedCompany.role === 'partner' ? this.props.selectedCompany.contact_id : config.YUSO_PARTNER_CONTACT_ID,
        product_id: this.props.sdp.type_sdp === 'injection' ? 3 : 1
      }
    },
      () => this.updatePartners())
  }

  resetForm() {
    this.setState({ contract: { ...this.initialState, ...this.props.sdp, product_id: this.props.sdp.type_sdp === 'injection' ? 3 : 1 } })
    this.updatePartners();
    this.updateFeesAndPrices();
  }

  onFixedFeeChange(e) {
    const newFixedFee = e.target.value
    this.setState(prevState => ({
      contract: {
        ...prevState.contract,
        fixedFee: newFixedFee
      }
    }), () => {
      this.updateFeesAndPrices();
    });
  }

  onPlatformFeeChange(e) {
    const newPlatformFee = e.target.value
    this.setState(prevState => ({
      contract: {
        ...prevState.contract,
        platformFee: newPlatformFee
      }
    }), () => {
      this.updateFeesAndPrices();
    });
  }

  onFieldChange(e) {
    if (e.target) {
      let name = e.target.name,
        contract = { ...this.state.contract }

      switch (name) {

        case 'switchType':
          contract.switchType = e.target.value;

          if (this.props.selectedCompany.role !== 'admin' && (e.target.value === 'Normal Switch' || e.target.value === 'Switch after EoC'))
            if (moment().add(1, 'months').startOf('month').diff(moment(), 'days') <= 21)
              this.setState({ initialMonth: moment().add(2, 'months').month() })
            else this.setState({ initialMonth: moment().add(1, 'months').month() })
          else if (this.props.selectedCompany.role !== 'admin' && e.target.value === 'Move-in')
            this.setState({ initialMonth: moment().month() })

          break;

        case 'Imbalance':
          contract.imbCostPct = parseInt(e.target.value)
          break;
        case 'WindImbalance':
          contract.windImbCostPct = parseInt(e.target.value)
          break;
        case 'vreg_id':
          contract.vreg_id = e.target.value.toUpperCase()
          break;

        case 'product_id':
          contract.product_id = e.target.value
          break;

        case 'priceGSC':
          contract.priceGSC = e.target.value > 0 ? e.target.value : 0
          break;

        case 'priceWKC':
          contract.priceWKC = e.target.value > 0 ? e.target.value : 0
          break;

        case 'GOprice':
          contract.GOprice = e.target.value > 0 ? e.target.value : 0
          break;

        default:
          break;
      }

      this.setState({ contract }, () => {
        if (name === 'product_id')
          this.updateFeesAndPrices(name === 'product_id' ? false : true)
      })
    }
    else {
      this.setState({ contract: { ...this.state.contract, startDate: e } });
    }
  }

  numberField(e) {
    if (!/^[0-9.Ee]+$/.test(String.fromCharCode(e.which)))
      e.preventDefault();
  }

  disabledDays(day) {

    if (this.state.contract.switchType === '') {
      return true
    }
    else if (this.state.contract.switchType === 'Move-in') {

      if (moment(day).startOf('day') < moment().startOf('day'))
        return true
      else
        return false

    }
    else if (this.state.contract.switchType === 'Internal Switch') {

      if (moment(day).startOf('day') < moment().add(1, 'months').startOf('month'))
        return true
      else
        return false

    }
    else {
      if (this.props.selectedCompany.role === 'admin') {

        if (moment(day).startOf('day') < moment().startOf('day'))
          return true
        else
          return false

      }
      else {

        if (moment().add(1, 'months').startOf('month').diff(moment(), 'days') <= 21) {

          if (moment(day).startOf('day') < moment().add(2, 'months').startOf('month'))
            return true
          else
            return false

        }
        else {

          if (moment(day).startOf('day') < moment().add(1, 'months').startOf('month'))
            return true
          else
            return false

        }
      }
    }
  }

  updatePartners() {
    getPartners((err, res) => {
      if (err)
        return window.reportError.send(err);

      const partner_id = this.props.selectedCompany.contact_partner_id ? this.props.selectedCompany.contact_partner_id : config.YUSO_PARTNER_ID, // if logged in user is not partner then fall back to Yuso as default partner
        partner = res.find(x => x.id === partner_id)

      this.setState({
        partners: res,
        contract: {
          ...this.state.contract,
          contract_partner_id: partner.id,
          contract_partner_contact_id: this.props.selectedCompany.contact_partner_id || config.YUSO_PARTNER_CONTACT_ID,
          activeCommission: partner.activeCommission,
          defaultPartnerFee: partner.defaultPartnerFee
        }
      }, this.updatePartnerContacts())
    })
  }

  updatePartnerContacts() {
    getPartnerContacts(this.state.contract.contract_partner_id, (err, res) => {
      if (err)
        return window.reportError.send(err);

      const partner_contact = res.find(x => x.id === this.props.selectedCompany.contact_id) || res[0]

      this.setState({
        partnerContacts: res,
        contract: { ...this.state.contract, contract_partner_contact_id: partner_contact ? partner_contact.id : '' }
      }, () => this.updateFeesAndPrices())
    })
  }

  onPartnerChange(e) {
    const partner = this.state.partners.find(x => x.id === parseInt(e.target.value))

    this.setState({
      contract: {
        ...this.state.contract,
        contract_partner_id: e.target.value,
        activeCommission: partner.activeCommission,
        defaultPartnerFee: partner.defaultPartnerFee
      },
      partnerContacts: []
    }, () => {
      this.updatePartnerContacts()
      if (partner.activeCommission)
        this.updateFeesAndPrices(true)
    })
  }

  onPartnerContactChange(e) {
    this.setState({
      contract: { ...this.state.contract, contract_partner_contact_id: e.target.value }
    })
  }

  updateFeesAndPrices(partnerFee) {
    if (this.state.contract.type_sdp === 'injection') {
      getFixedFee(
        this.props.selectedCompany.company_id,
        {
          activeCommission: this.state.contract.activeCommission,
          defaultPartnerFee: this.state.contract.defaultPartnerFee ? this.state.contract.defaultPartnerFee : null,
          fixedFee: this.state.contract.fixedFee,
          type: this.state.contract.type_sdp
        },
        (err, resFixedFee) => {
          if (err) {
            return window.reportError.send(err);
          }

          this.getFeesAndPrices(partnerFee, resFixedFee);
        }
      );
    } else {
      this.getFeesAndPrices(partnerFee);
    }
  }

  getFeesAndPrices(partnerFee, resFixedFee) {
    getFeesAndPrices(
      this.props.selectedCompany.company_id,
      {
        type: this.state.contract.type_sdp,
        platformFee: this.state.contract.platformFee || null,
        activeCommission: this.state.contract.activeCommission,
        defaultPartnerFee: this.state.contract.defaultPartnerFee ? this.state.contract.defaultPartnerFee : null,
        partnerFeeFlag: partnerFee,
        product_id: this.state.contract.product_id,
        fixedFee: this.state.contract.fixedFee
      },
      (err, res) => {
        if (err) {
          return window.reportError.send(err);
        }

        this.setState(partnerFee ?
          {
            contract: {
              ...this.state.contract,
              partnerFee: this.state.contract.type_sdp === 'injection' ? (resFixedFee ? resFixedFee.partnerFee : null) : res.partnerFee,
            }
          }
          :
          {
            contract: {
              ...this.state.contract,
              minFixedFee: resFixedFee ? resFixedFee.minFixedFee : 5,
              minPlatformFee: res.minPlatformFee,
              platformFee: this.props.selectedCompany.role === 'admin' ? this.state.contract.platformFee : res ? res.platformFee : this.state.contract.platformFee,
              imbCostPct: this.state.contract.type_sdp === 'injection' && this.state.contract.technology ? (
                this.state.contract.technology.includes('Solar') && this.state.contract.technology.includes('Wind')
                  ? res.minDoubleImbalance
                  : this.state.contract.technology.includes('Solar')
                    ? res.minSolarImbalance
                    : null
              ) : null,
              windImbCostPct: this.state.contract.type_sdp === 'injection' && this.state.contract.technology ? (
                this.state.contract.technology.includes('Solar') && this.state.contract.technology.includes('Wind')
                  ? res.minDoubleImbalance
                  : this.state.contract.technology.includes('Wind')
                    ? res.minWindImbalance
                    : null
              ) : null,
              fixedFee: resFixedFee ? resFixedFee.fixedFee : this.state.contract.fixedFee,
              partnerFee: this.state.contract.type_sdp === 'injection' ? (resFixedFee ? resFixedFee.partnerFee : null) : res.partnerFee,
              GOprice: res.go_price
            },
            previousPrices: res.previousPrices
          }
        );
      }
    );
  }

  onSubmit(e) {
    e.preventDefault();

    if (!this.props.selectedCompany) return;

    this.setState({ error: false, adding: true, added: undefined });

    const form = e.target.elements;

    for (let i = 0; i < form.length; i++) {
      if ((form[i].name && form[i].required && !form[i].value.trim().length) || !this.state.contract.type_sdp) {
        this.setState({ error: this.strings.errorRequired, adding: false });
        return;
      }
    }

    const vreg_id = e.target.vreg_id ? e.target.vreg_id.value.trim().toUpperCase() : null;

    // if user entered VREG ID validate it
    if (vreg_id) {
      const vreg_list = vreg_id.split(',').map(x => x.trim());

      const check = vreg_list.find(x => x.startsWith('PVZG') || x.startsWith('PVZ') || x.startsWith('G') || x.startsWith('WKK'));
      if (!check) {
        this.setState({ error: this.strings.errorWrongFormat, adding: false });
        return;
      }
    }

    addContract(
      this.props.selectedCompany.company_id,
      this.state.contract,
      (err, res) => {
        if (err) return window.reportError.send(err);
        if (res.error) {
          this.setState({ adding: false, error: this.strings.error });
        } else {
          this.setState({ adding: false, added: true }, () => setTimeout(() => this.props.updateSdps('contract'), 3000));
        }
      }
    );
  }


  render() {
    if (!this.props.selectedCompany) {
      return (
        <Loader inline="true">{this.strings.loading}</Loader>
      );
    }

    if (this.state.added)
      return (
        <div className="row">
          <div className="col-6 pr-2 pr-xl-5">
            <p className="alert alert-info mb-4">
              <i className="fas fa-info-circle mr-2"></i> {this.strings.addedContract}
            </p>
          </div>
        </div>
      );

    return (
      <form onSubmit={this.onSubmit} noValidate>
        <div className='row'>
          <div className='col-6'>
            <h5 className="text-primary font-weight-light mb-4">
              <strong>{this.strings.contractParams}</strong>&nbsp;
              {
                this.state.contract.type_sdp === 'offtake' ? <span>{this.strings.supplyPoint}</span>
                  : this.state.contract.type_sdp === 'injection' ? <span>{this.strings.injectionPoint}</span>
                    : <span>{this.strings.sdpPoint}</span>
              }
            </h5>

            {
              this.state.contract.type_sdp === "injection" ? null : <div className="form-group row required required-select">
                <label className="col-sm-3 col-form-label">{this.strings.switchType}</label>
                <div className="col-sm">
                  <select className="form-control" id="switchType" name="switchType" value={this.state.contract.switchType} onChange={this.onFieldChange} required>
                    {
                      this.props.sdp.switchType === "Internal Switch"
                        ? <option value="Internal Switch" key={"Internal_Switch"} selected>{this.strings.switchTypeInternalSwitch}</option>
                        :
                        [
                          <option value="" key={null} selected disabled> </option>,
                          <option value="Move-in" key={'Move_in'}>{this.strings.switchTypeMoveIn}</option>,
                          <option value="Normal Switch" key={'Normal_Switch'}>{this.strings.switchTypeNormalSwitch}</option>,
                          <option value="Switch after EoC" key={'Switch_after_EoC'}>{this.strings.switchTypeSwitchAfterEoC}</option>
                        ]
                    }
                  </select>
                </div>
              </div>
            }

            <div className="form-group row required">
              <label className="col-sm-3 col-form-label">{this.strings.startDate}</label>
              <div className="col-sm date-picker-full">
                <DayPickerInput
                  value={this.state.contract.startDate ? moment(this.state.contract.startDate, 'YYYY-MM-DD').toDate() : ''}
                  placeholder=""
                  format="DD-MM-YYYY"
                  formatDate={(date, format) => { return moment(date).format(format) }}
                  inputProps={{ className: 'form-control', readOnly: 'readonly', required: 'required', name: 'startDate', id: 'startDate' }}
                  dayPickerProps={{ selectedDays: this.state.contract.startDate, disabledDays: this.disabledDays, initialMonth: new Date(moment().add(moment().month() <= this.state.initialMonth ? 0 : 1, "year").year(), this.state.initialMonth) }}
                  onDayChange={this.onFieldChange}
                />
              </div>
              {
                <small className="form-text text-muted text-right mx-3"><strong>{this.strings.note}</strong> {this.strings.startDateNote}</small>
              }
            </div>
            {
              this.state.contract.type_sdp === 'injection' ?
                <>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">{this.strings.vreg}</label>
                    <div className="col-sm">
                      <input className="form-control" type="text" name="vreg_id" value={this.state.contract.vreg_id} onChange={this.onFieldChange} />
                    </div>
                  </div>
                  <div className="form-group row required required-select">
                    <label className="col-sm-3 col-form-label">{this.strings.product}</label>
                    <div className="col-sm">
                      <select
                        className="form-control"
                        id="product_id"
                        name="product_id"
                        value={this.state.contract.product_id}
                        onChange={this.onFieldChange}
                        required
                      >
                        <option value="3">YUSO Dynamic Injection Solar</option>
                      </select>
                    </div>
                  </div>
                </>
                : <div className="form-group row required required-select">
                  <label className="col-sm-3 col-form-label">{this.strings.product}</label>
                  <div className="col-sm">
                    <select className="form-control" id="product_id" name="product_id" defaultValue={this.state.contract.product_id} onChange={this.onFieldChange} required>
                      <option value="1" key="YusoDynamicGreen">YUSO Dynamic Green</option>
                      <option value="2" key="YusoDynamicGreenLocal">YUSO Dynamic Green Local</option>
                    </select>
                  </div>
                </div>
            }
            {
              // this.props.selectedCompany.role === 'admin' || this.props.selectedCompany.role === 'partner' ?
              this.state.contract.type_sdp === "injection" ?
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">{this.strings.fixedFee}</label>
                  <div className="col-sm">
                    <div className="input-group-append">
                      <NumberInput className="form-control" type="number" name="fixedFee"
                        value={this.state.contract.fixedFee}
                        onChange={this.onFixedFeeChange}
                        // onBlur={this.onFieldChange}
                        disabled={this.props.selectedCompany.role === 'user'}
                      />
                      <div className="input-group-text form-control bg-warning select-unit">€/MWh</div>
                    </div>
                    {
                      this.state.contract.minFixedFee ? <small className="form-text text-danger"><strong>{this.strings.minFixedFee}</strong> {this.state.contract.minFixedFee} €/MWh.</small> : null
                    }
                    {
                      this.state.previousPrices && this.state.previousPrices.type_sdp === this.state.contract.type_sdp ?
                        <small className="form-text text-danger"><strong>{this.strings.minFixedFee}</strong> {this.state.contract.type_sdp === 'offtake' ? this.strings.previousFeeSupply : this.state.contract.type_sdp === 'injection' ? this.strings.previousFeeInjection : null} <strong>{this.state.previousPrices.platformFee} €/MWh</strong>.</small>
                        : null
                    }
                  </div>
                </div> :
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">{this.strings.platformFee}</label>
                  <div className="col-sm">
                    <div className="input-group-append">
                      <NumberInput className="form-control" type="number" name="platformFee"
                        value={this.state.contract.platformFee}
                        onChange={this.onPlatformFeeChange}
                        // onBlur={this.onFieldChange}
                        disabled={this.props.selectedCompany.role === 'user'}
                      />
                      <div className="input-group-text form-control bg-warning select-unit">€/MWh</div>
                    </div>
                    {
                      this.state.contract.minPlatformFee ? <small className="form-text text-danger"><strong>{this.strings.note}</strong> {this.strings.minPlatformFee} {this.state.contract.minPlatformFee} €/MWh.</small> : null
                    }
                    {
                      this.state.previousPrices && this.state.previousPrices.type_sdp === this.state.contract.type_sdp ?
                        <small className="form-text text-danger"><strong>{this.strings.note}</strong> {this.state.contract.type_sdp === 'offtake' ? this.strings.previousFeeSupply : this.state.contract.type_sdp === 'injection' ? this.strings.previousFeeInjection : null} <strong>{this.state.previousPrices.platformFee} €/MWh</strong>.</small>
                        : null
                    }
                  </div>
                </div>
              // : null
            }
            {
              this.props.selectedCompany.role === 'admin' && this.state.contract.type_sdp === "injection" && (
                <>
                  {this.state.contract.technology && (
                    <>
                      {this.state.contract.technology.includes('Solar') && (
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">{this.strings.SolarImbalanceIndex}</label>
                          <div className="col-sm">
                            <div className="input-group-append">
                              <NumberInput
                                className="form-control"
                                type="number"
                                name="Imbalance"
                                value={this.state.contract.imbCostPct}
                                onChange={e =>
                                  this.setState({
                                    contract: { ...this.state.contract, imbCostPct: parseInt(e.target.value) }
                                  })
                                }
                                onBlur={this.onFieldChange}
                                disabled={this.props.selectedCompany.role !== 'admin' && !this.state.contract.activeCommission}
                              />
                              <div className="input-group-text form-control bg-warning select-unit">%</div>
                            </div>
                            {this.state.contract.imbCostPct ? (
                              <small className="form-text text-danger">
                                <strong>{this.strings.standardPercentage}</strong> {this.strings.imbCostPct} {this.state.contract.minImbalanceIndex} %.
                              </small>
                            ) : null}
                          </div>
                        </div>
                      )
                      }

                      {
                        this.state.contract.technology.includes('Wind') && (
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{this.strings.WindImbalanceIndex}</label>
                            <div className="col-sm">
                              <div className="input-group-append">
                                <NumberInput
                                  className="form-control"
                                  type="number"
                                  name="WindImbalance"
                                  value={this.state.contract.windImbCostPct}
                                  onChange={e =>
                                    this.setState({
                                      contract: { ...this.state.contract, windImbCostPct: parseInt(e.target.value) }
                                    })
                                  }
                                  onBlur={this.onFieldChange}
                                  disabled={this.props.selectedCompany.role !== 'admin' && !this.state.contract.activeCommission}
                                />
                                <div className="input-group-text form-control bg-warning select-unit">%</div>
                              </div>
                              {this.state.contract.windImbCostPct ? (
                                <small className="form-text text-danger">
                                  <strong>{this.strings.standardPercentage}</strong> {this.strings.imbCostPct} {this.state.contract.minImbalanceIndex} %.
                                </small>
                              ) : null}
                            </div>
                          </div>
                        )}
                    </>
                  )}

                  {this.state.previousPrices && this.state.previousPrices.type_sdp === this.state.contract.type_sdp ? (
                    <small className="form-text text-danger">
                      <strong>{this.strings.standardPercentage}</strong> {this.state.contract.type_sdp === 'offtake'
                        ? this.strings.previousFeeSupply
                        : this.state.contract.type_sdp === 'injection'
                          ? this.strings.previousFeeInjection
                          : null}{' '}
                      <strong>{this.state.previousPrices.platformFee} €/MWh</strong>.
                    </small>
                  ) : null}
                </>
              )
            }

            {
              this.state.contract.activeCommission ?
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">{this.strings.partnerFee}</label>
                  <div className="col-sm">
                    <div className="input-group-append">
                      <NumberInput className="form-control" type="number" name="partnerFee" value={this.state.contract.partnerFee}
                        disabled={this.props.selectedCompany.role !== 'admin'} />
                      <div className="input-group-text form-control bg-warning select-unit">€/MWh</div>
                    </div>
                    <small className="form-text text-muted"><strong>{this.strings.note}</strong> {this.state.contract.type_sdp === 'offtake' ? this.strings.partnerFeeNote1 : this.strings.partnerFeeNote2}</small>
                  </div>
                </div>
                : null
            }

            <p className={'alert alert-warning ' + (this.state.error ? 'd-block' : 'd-none')}>
              <i className="fas fa-exclamation-triangle mr-2"></i> {this.state.error}
            </p>

            <div className="d-flex">
              <button type="reset" className="btn btn-secondary text-capitalize px-4 py-3" onClick={this.resetForm}><strong>{this.strings.clear}</strong> {this.strings.form}</button>

              <button type="submit" className="btn btn-primary text-capitalize px-4 py-3 ml-auto" disabled={this.state.adding ? 'disabled' : undefined} style={{ width: '240px' }}>
                {this.state.adding ? <i className="fas fa-spinner fa-spin mr-2"></i> : this.strings.generateContract}
              </button>
            </div>

          </div>
          <div className='col-6'>
            {
              this.props.selectedCompany.role === 'admin' ?
                <div className="bg-light border border-dark pt-3 px-3 mb-3">
                  <h5 className="mb-3">{this.strings.adminFields}:</h5>
                  {
                    this.state.contract.type_sdp === 'offtake' ?
                      <div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">{this.strings.priceGSC}</label>
                          <div className="col-sm">
                            <NumberInput className="form-control" type="number" name="priceGSC" min="0" onChange={this.onFieldChange} />
                            {
                              this.state.previousPrices && this.state.previousPrices.priceGSC ?
                                <small className="form-text text-danger"><strong>{this.strings.note}</strong> {this.strings.previousPriceGSC} <strong>{this.state.previousPrices.priceGSC} EUR</strong>. {this.strings.leaveEmptyCert}</small>
                                : null
                            }
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">{this.strings.priceWKC}</label>
                          <div className="col-sm">
                            <NumberInput className="form-control" type="number" name="priceWKC" min="0" onChange={this.onFieldChange} />
                            {
                              this.state.previousPrices && this.state.previousPrices.priceWKC ?
                                <small className="form-text text-danger"><strong>{this.strings.note}</strong> {this.strings.previousPriceWKC} <strong>{this.state.previousPrices.priceWKC} EUR</strong>. {this.strings.leaveEmptyCert}</small>
                                : null
                            }
                          </div>
                        </div>
                      </div> : null
                  }
                  {
                    this.state.contract.type_sdp === 'injection' ?
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">{this.strings.GOprice}</label>
                        <div className="col-sm">
                          <NumberInput className="form-control" type="number" name="GOprice" min="0" onChange={this.onFieldChange} />
                          <small className="form-text text-danger"><strong>{this.strings.note}</strong> {this.strings.defaultGOprice} <strong>{this.state.contract.GOprice} EUR</strong>. {this.strings.leaveEmpty}</small>
                          {/* {
                            this.state.previousPrices && this.state.previousPrices.GOprice ?
                              <small className="form-text text-danger"><strong>{this.strings.note}</strong> {this.strings.newGOprice} <strong>{this.state.previousPrices.GOprice} EUR</strong>. {this.strings.leaveEmptyCert}</small>
                              : null
                          } */}
                        </div>
                      </div> : null
                  }

                  <div className="alert alert-info text-white">
                    <i className="fas fa-info-circle mr-2"></i> {this.strings.partnerInfo1}
                    <ul>
                      <li>{this.strings.if} <strong>{this.strings.partner}</strong> {this.strings.partnerInfo2}</li>
                      <li>{this.strings.if} <strong>{this.strings.adminitrator}</strong> {this.strings.partnerInfo3} <em>{this.strings.partnerInfo4}</em></li>
                      <li>{this.strings.if} <strong>{this.strings.user}</strong> {this.strings.partnerInfo5}</li>
                    </ul>
                  </div>

                  <div className="form-group row required required-select">
                    <label className="col-sm-3 col-form-label">{this.strings.partnerCompany}</label>
                    <div className="col-sm">
                      <select className="form-control" id="contract_partner_id" name="partner" value={this.state.contract.contract_partner_id} onChange={this.onPartnerChange} required>
                        <option value="" disabled>{this.strings.partnerSelect}</option>
                        {
                          this.state.partners.map(partner => <option key={partner.id} value={partner.id}>{partner.name}</option>)
                        }
                      </select>
                    </div>
                  </div>

                  <div className="form-group row required required-select">
                    <label className="col-sm-3 col-form-label">{this.strings.partnerLabel}</label>
                    <div className="col-sm">
                      <select className="form-control mb-3" id="contract_partner_contact_id" name="partner_contact" value={this.state.contract.contract_partner_contact_id} onChange={this.onPartnerContactChange} required>
                        {
                          this.state.partnerContacts.map(contact => <option key={contact.id} value={contact.id}>{contact.fullName}</option>)
                        }
                      </select>
                    </div>
                  </div>
                </div> : null
            }
          </div>
        </div>

      </form>
    );
  }
}

export default NewContract;
